@import './core';

.how-buy-landing__steps {
  display: flex;
  justify-content: center;
  padding: rem(92px) 0 rem(50px) 0;

  .steps__separator {
    background: rgba($black, 0.2);
    height: rem(76px);
    position: relative;
    top: rem(126px);
    width: rem(1px);

    &:first-child {
      display: none;
    }
  }

  .steps__step {
    box-sizing: border-box;
    margin: 0 rem($andes-spacing-40);
    width: rem(304px);
  }

  .step__title {
    padding: rem($andes-spacing-24) 0 rem($andes-spacing-12) 0;
  }
}
