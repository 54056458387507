// > Spacing
// ----------------------------------------------------------------
// The classes are named using the format: {property}-{sides}-{size}
//
// Where property is one of:
//   * m - for classes that set margin
//   * p - for classes that set padding
//
// Where sides is one of:
//   * t - for classes that set margin-top or padding-top
//   * b - for classes that set margin-bottom or padding-bottom
//   * l - for classes that set margin-left or padding-left
//   * r - for classes that set margin-right or padding-right
//   * x - for classes that set both *-left and *-right
//   * y - for classes that set both *-top and *-bottom
//   * a - for classes that set a margin or padding on all 4 sides of the element

@for $i from 0 through 5 {
  .p-l-#{$i} {
    @include spacing-padding(left, $mdu * $i, '!important');
  }
  .p-r-#{$i} {
    @include spacing-padding(right, $mdu * $i, '!important');
  }
  .p-t-#{$i} {
    @include spacing-padding(top, $mdu * $i, '!important');
  }
  .p-b-#{$i} {
    @include spacing-padding(bottom, $mdu * $i, '!important');
  }
  .p-x-#{$i} {
    @include spacing-padding(x, $mdu * $i, '!important');
  }
  .p-y-#{$i} {
    @include spacing-padding(y, $mdu * $i, '!important');
  }
  .p-a-#{$i} {
    @include spacing-padding(a, $mdu * $i, '!important');
  }

  .m-l-#{$i} {
    @include spacing-margin(left, $mdu * $i, '!important');
  }
  .m-r-#{$i} {
    @include spacing-margin(right, $mdu * $i, '!important');
  }
  .m-t-#{$i} {
    @include spacing-margin(top, $mdu * $i, '!important');
  }
  .m-b-#{$i} {
    @include spacing-margin(bottom, $mdu * $i, '!important');
  }
  .m-x-#{$i} {
    @include spacing-margin(x, $mdu * $i, '!important');
  }
  .m-y-#{$i} {
    @include spacing-margin(y, $mdu * $i, '!important');
  }
  .m-a-#{$i} {
    @include spacing-margin(a, $mdu * $i, '!important');
  }
}
