@import '../../../styles/variables';
@import '../components/Condition/styles/core';

.bank-conditions-landing__section .ui-ms-bank-conditions-landing__title {
  font-size: rem($font-size-28);
  font-weight: $font-weight-semibold;
  line-height: $line-height-s;
  margin: 0 rem($andes-spacing-16);
  padding: 0 0 rem($andes-spacing-28);
}
