@import 'variables';

.hub__carousel {
  border: 0;
  margin: rem($andes-spacing-20) auto;
  max-height: rem(200px);
  max-width: rem($boxed-max-width);
  outline: 0;
  overflow: hidden;
  padding: rem($andes-spacing-20) 0;
  vertical-align: baseline;

  .andes-carousel-snapped__slide {
    justify-content: center;
  }

  .andes-carousel-snapped__wrapper .andes-carousel-snapped__slide img {
    height: auto;
  }

  .carousel__ul,
  .carousel__brands-ul {
    margin: 0 auto;
    max-width: $carousel-width;
    position: relative;
    text-align: center;

    .carousel-mobile-list {
      margin: 0 auto;

      &-element {
        padding-bottom: rem($andes-spacing-12);
        vertical-align: top;

        &:first-child {
          margin-left: rem($andes-spacing-4);
        }
      }
    }

    .carousel__item {
      height: auto;
      width: rem(88px);

      &-img {
        background-color: $andes-white;
        box-sizing: border-box;
        height: rem(88px);
        overflow: hidden;
        padding: rem(10px);
        position: relative;
        text-align: center;
        z-index: 1;

        img {
          display: inline;
          width: 100%;
        }

        @media (min-width: $screen-mobile) {
          height: 100px;
        }
      }

      a {
        text-decoration: none;
      }

      @media (min-width: $screen-mobile) {
        width: 100px;
      }
    }

    @media (max-width: $screen-mobile) {
      min-width: 100%;
    }
  }

  .carousel__ul--square,
  .carousel__brands-ul--square {
    .carousel__item-img {
      border-radius: 0;
    }
  }

  .carousel__ul--circle,
  .carousel__brands-ul--circle {
    .carousel__item-img {
      border-radius: 50%;
    }
  }

  // SPECIFIC CATEGORY STYLES
  .carousel__ul {
    .carousel__item-title {
      color: $ml-gray-dark;
      font-size: $subtitle-size - 4;
      font-weight: $fw-regular;
      line-height: 1.2;
      margin: 0;
      padding-top: rem($andes-spacing-12);
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      white-space: normal;

      @media (min-width: $screen-mobile) {
        padding-top: rem($andes-spacing-16);
      }
    }
  }

  // SPECIFIC BRAND STYLES
  .carousel__brands-ul {
    .carousel__item-img {
      align-items: center;
      display: flex;
    }

    .carousel__item {
      display: inline-block;
      height: auto;
    }

    .carousel__item-title {
      display: none;

      @media (min-width: $screen-mobile) {
        display: none;
      }
    }
    @media (max-width: $screen-mobile) {
      margin: 0 auto;
      max-width: $carousel-width;
      padding: 0;
      position: relative;
      text-align: center;
    }
  }

  .andes-carousel-snapped__control {
    &:not(:focus-visible):focus,
    &:not(:focus-visible):hover {
      box-shadow: none;
    }

    svg,
    path {
      fill: $ml-gray-darker-2;
    }
  }

  .andes-carousel-snapped__control--next,
  .andes-carousel-snapped__control--previous {
    background: 0 0;
    background-color: transparent;
    box-shadow: none;
  }

  .title {
    color: $ml-gray-darker-2;
    display: block;
    font-family: 'Proxima Nova';
    font-size: $subtitle-size;
    font-weight: $fw-regular;
    letter-spacing: 1.5px;
    margin: 0;
    padding: 0 rem(10px) rem(25px);
    text-align: center;
    text-transform: uppercase;

    @media (min-width: $screen-mobile) {
      padding-bottom: rem($andes-spacing-24);
    }
  }

  @media (min-width: $screen-mobile) {
    overflow: hidden;
    padding: rem(30px) 0;
  }
}
