@import './core';

.how-buy-landing__title-section {
  max-width: $max-width;
  padding-top: $andes-spacing-48;

  .title-section__text {
    margin-top: rem(30px);

    strong {
      padding: 0 $andes-spacing-4;
    }
  }
}
