.ui-ms-purchase-access {
  animation: fadeInScale .8s ease-out forwards;
  background-color: $andes-white;
  border-radius: rem(6px);
  box-shadow: $shadow-elevated;
  display: flex;
  justify-content: center;
  opacity: 0;
  padding: rem($andes-spacing-16);
  position: fixed;
  right: rem($andes-spacing-16);
  top: 50%;
  z-index: 1;
}

/* Animation */
@keyframes fadeInScale {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.home--legend + .ui-ms-purchase-access {
  border-radius: 0;
}

.ui-ms-purchase-access__wrapper {
  display: flex;
}

.ui-ms-purchase-access__content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-right: rem(10px);
}

.ui-ms-purchase-access__icon path {
  fill: var(--primaryTextColorVar);
}

.ui-ms-purchase-access__icon-content {
  align-items: center;
  background-color: var(--tertiaryColorVar);
  border-radius: 50%;
  display: flex;
  height: rem(32px);
  justify-content: center;
  margin-right: rem($andes-spacing-8);
  width: rem(32px);
}

.ui-ms-purchase-access__title {
  color: $andes-text-color-secondary;
  font-family: var(--primaryFontVar);
  font-size: rem($font-size-14);
  line-height: rem(18px);
  padding-bottom: rem($andes-spacing-4);
}

.ui-ms-purchase-access__button.andes-button--transparent {  
  color: $link-active;
  font-family: var(--secondaryFontVar);
  height: initial;
  line-height: rem(15px);
  padding: 0;

  &:hover,
  &:link:hover,
  &:visited:hover,
  &:link,
  &:visited,
  &:focus {
    background-color: transparent;
    color: $link-active;
  }
}

.ui-ms-purchase-access__close-button.andes-button--transparent {
  border: none;
  height: rem(16px);
  padding: 0;
  width: rem(16px);

  &:hover,
  &:link,
  &:visited,
  &:focus {
    background-color: transparent;
  }
  
  svg[fill]:not([fill=none]),
  path[fill]:not([fill=none]) {
    fill: $andes-gray-550 !important;
  }
}
