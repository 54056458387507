@import '../../styles/variables';

.protected-purchase__container {
  background-color: $andes-gray-040-solid;

  .title-section__container-title {
    margin: 0 auto;
    max-width: $protected-purchase-view-max-width;
    padding: rem(96px) rem($andes-spacing-40) rem(70px);
    text-align: left;

    .title-section__title {
      font-size: $font-size-24;
      font-weight: $font-weight-semibold;
    }
  }
}
