.not-found-page {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: rem($andes-spacing-56);
}

.not-found-page__texts {
  margin-left: rem(90px);
}

.not-found-page__img {
  height: rem(355px);
}

.not-found-page__title {
  color: $andes-text-color-disabled;
  font-size: rem(56px);
  font-weight: $font-weight-bold;
  letter-spacing: rem(1.1px);
  line-height: rem(40px);
  margin-bottom: rem($andes-spacing-12);
}

.not-found-page__subtitle {
  color: $andes-text-color-primary;
  font-size: rem($font-size-24);
  font-weight: $font-weight-bold;
  letter-spacing: rem(0.5px);
  line-height: rem(40px);
  margin-bottom: rem($andes-spacing-4);
}

.not-found-page__link {
  color: $andes-accent-color;
  font-size: rem($font-size-14);
  font-weight: $font-weight-bold;
  line-height: rem(40px);
  text-decoration: underline !important;
}
