@import '../../styles/variables';

.legal-notices__info-section {
  background-color: $andes-white;
  padding: rem($andes-spacing-64) 0 rem(192px);

  .legal-notices__info-container {
    margin: 0 auto;
    max-width: $legal-notices-view-max-width;
  }

  .title-section__subtitle {
    font-size: $font-size-20;
    margin-top: rem($andes-spacing-8);
    max-width: rem($legal-notices-content-max-width);
  }

  .title-section__last-update {
    color: $andes-gray-900-solid;
    font-size: $font-size-12;
    margin-top: rem($andes-spacing-12);
    max-width: rem($legal-notices-content-max-width);
  }

  .title-section__text {
    font-size: $font-size-16;
    line-height: $paragraph-line-height;
    margin: rem($andes-spacing-64) 0;
    max-width: rem($legal-notices-content-max-width);
  }

  .title-section__link {
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    max-width: rem($legal-notices-content-max-width);
  }
}
