.cancel-buy-landing__section {
  .ui-ms-cancel-buy-landing__title {
    font-size: $font-size-24;
    font-weight: $font-weight-semibold;
    line-height: $line-height-s;
    margin: 0;
    padding: 0 0 rem($andes-spacing-28);
  }

  .cancel-buy-landing__card {
    .cancel-buy-landing__content {
      h3 {
        font-size: $font-size-18;
        line-height: $line-height-s;
        margin: rem($andes-spacing-16) 0;

        & strong {
          font-weight: $font-weight-semibold;
        }
      }

      & :first-child {
        margin-top: 0;
        padding-top: 0;
      }

      p {
        font-weight: $font-weight-light;
        margin: rem($andes-spacing-16) 0;
      }

      a.cancel-buy-landing__bold {
        font-weight: $font-weight-semibold;
      }
    }
  }
}
