@import '../../styles/variables';

.legal-notices__title-section {
  margin: 0 auto;
  max-width: rem($legal-notices-view-max-width);
  padding: rem(96px) 0 rem(70px);
  text-align: left;

  .title-section__title {
    font-size: $font-size-24;
    font-weight: $font-weight-semibold;
  }
}
