.simple-landing__info-section {
  background-color: $andes-white;
  padding: ($mdu * 8) 0 ($mdu * 24);

  .simple-landing__info-container {
    margin: 0 auto;
    max-width: $legal-notices-view-max-width;
  }

  .info-section__term {
    margin: ($mdu * 2) 0;

    h3 {
      font-weight: $font-weight-semibold;
      margin-bottom: $mdu;
    }

    ul {
      margin: ($mdu * 2);

      li {
        list-style: disc;
        margin-bottom: $mdu;
      }
    }
  }

  ol {
    margin-left: ($mdu * 2);
  }

  ol > li {
    list-style: lower-alpha;
  }
}
