@import '../../styles/variables';

.how-buy-landing__more-info-message {
  background: $andes-gray-070-solid;
  padding: rem($andes-spacing-24) 0;
  text-align: center;

  p {
    margin: 0 auto;
  }

  .more-info-message__link {
    color: $andes-blue-400;
    cursor: pointer;
    padding-left: rem($andes-spacing-4);
  }
}
