@import '../../styles/variables';

.protected-purchase__info-section {
  background-color: $andes-white;

  .protected-purchase__info-container {
    padding: rem($andes-spacing-32) rem($andes-spacing-40)
      rem($andes-spacing-64);
    margin: 0 auto;
    max-width: $protected-purchase-view-max-width;

    .title-section__text {
      font-size: $font-size-16;
      font-weight: $font-weight-regular;
      line-height: $line-height-s;
      margin-bottom: rem($andes-spacing-12);
    }

    .title-section__link {
      font-size: $font-size-16;
      font-weight: $font-weight-regular;
      margin-top: rem($andes-spacing-20);
    }
  }
}
