.contact-info {
  background-color: $andes-white;
}

.contact-info__title {
  color: $light-black;
  font-size: 3 * $mdu;
  font-weight: $font-weight-semibold;
  margin-bottom: 5 * $mdu;
}

.contact-info__label {
  color: $gray;
  font-size: $font-size-14;
}

.contact-info__block .contact-info__data {
  font-size: $font-size-18;
}

.remark-data .contact-info__data {
  font-size: 3 * $mdu;
}

.remark-data ~ .contact-info__social-networks .contact-data__social::before {
  transform: $rrss-icons-scale;
}

.remark-data.remark-address {
  & ~ .contact-info__social-networks .contact-data__social::before,
  .contact-info__data {
    font-size: $font-size-18;
  }

  .contact-info__address {
    font-size: 3 * $mdu;
  }
}

.contact-info__data {
  color: $light-black;
  margin-bottom: 4 * $mdu;
  opacity: 0.87;

  a {
    color: $light-black;
    text-decoration: none;
  }
}

.contact-info__social-links [href]::before {
  color: $andes-black;
  opacity: 0.38;
}

// Map - info-window
.info-box-window {
  background-color: $andes-white;
}

.info-window {
  font-family: $font-family-primary;
  padding: $mdu;
}

.info-window__title {
  color: $dark-gray;
  font-size: 2 * $mdu;
  font-weight: $font-weight-semibold;
  margin-bottom: $mdu;
}

.info-window__text {
  color: $dark-gray;
  font-size: $font-size-14;
}

.nav-footer-social-links-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.nav-footer-social-links {
  align-items: center;
  display: flex;
  padding-top: (2 * $mdu);
}

// Hide text in social links anchors
.nav-footer-social-link-item {
  font-size: 0;
}
