$mesh: (
  properties: (
    columns: 12,
  ),
  breakpoints: (
    smallest: (
      name: mobile,
      value: 0,
      gutter: 4px,
    ),
    large: (
      name: desktop,
      value: 1024px,
      gutter: 8px,
    ),
  ),
) !default;

.hub__boxed-width {
  padding: 0 rem($andes-spacing-8);

  @media (min-width: $breakpoint-desktop) {
    margin: rem($andes-spacing-32) 0;
    padding: 0;
  }
}

// Helpers
// ---------------------------------------------------------------- //

@function get-mesh-map($keys...) {
  $map: $mesh;

  @each $key in $keys {
    @if map-has-key($map, $key) {
      $map: map-get($map, $key);
    } @else {
      @error 'The `#{$key}` key does not exist in `$mesh` map.';
    }
  }

  @return $map;
}

// Row
// ---------------------------------------------------------------- //

.mesh-row {
  padding: 0;

  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
  }
}

.mesh-row.no-reset {
  margin: 0;
}

@each $breakpoint in map-keys(get-mesh-map(breakpoints)) {
  @media only screen and (min-width: get-mesh-map(breakpoints, $breakpoint, value)) {
    .mesh-row {
      margin: (get-mesh-map(breakpoints, $breakpoint, gutter) * -1);
    }
  }
}

// Columns
// ---------------------------------------------------------------- //

[class*='mesh-col-'] {
  box-sizing: border-box;
  float: left;
  width: 100%;
}

// Width-agnostic columns
// -------------------------------------------- //

@for $columns from 1 through get-mesh-map(properties, columns) {
  .mesh-col-#{$columns} {
    width: percentage($columns / get-mesh-map(properties, columns));
  }
}

// Responsive columns
// -------------------------------------------- //

@each $breakpoint in map-keys(get-mesh-map(breakpoints)) {
  @media only screen and (min-width: get-mesh-map(breakpoints, $breakpoint, value)) {
    [class*='mesh-col-'] {
      padding: get-mesh-map(breakpoints, $breakpoint, gutter);
    }

    @for $columns from 1 through get-mesh-map(properties, columns) {
      .mesh-col-#{get-mesh-map(breakpoints, $breakpoint, name)}-#{$columns} {
        width: percentage($columns / get-mesh-map(properties, columns));
      }
    }
  }
}
