@import '../../../../../styles/variables';

.ui-ms-condition-container {
  &:not(:first-child)::before {
    border-bottom: 1px dashed $payment-border-color;
    content: '';
    display: block;
    margin: rem($andes-spacing-20) 0;
  }

  &::after {
    content: '';
    clear: both;
    display: table;
  }

  .condition-short-info {
    height: fit-content;
    vertical-align: top;
    width: rem($payment-short-info-width);

    .condition-logo {
      height: auto;
      margin-top: rem($andes-spacing-8);
      max-width: rem($payment-logo-width);
    }

    .condition-sub-info {
      color: $payment-legals-text-color;
      font-size: rem($font-size-14);
      margin-top: rem($andes-spacing-16);
      text-align: left;
    }

    .condition-name {
      color: $payment-title-text-color;
      font-size: rem($font-size-14);
      font-weight: $font-weight-semibold;
      text-align: left;
    }
  }

  .condition-more-info {
    overflow: hidden;
    vertical-align: top;

    .condition-title {
      font-size: rem($font-size-24);
      font-weight: $font-weight-semibold;
      margin-top: 0;
      text-align: left;
    }

    .condition-legals {
      color: $payment-legals-text-color;
      font-size: rem($font-size-12);
      margin-top: rem($andes-spacing-20);
      text-align: left;
    }
  }
}
