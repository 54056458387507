@import '../../../styles/variables';

.lgpd-landing__info-section {
  background-color: $andes-white;
  padding: 0 (4 * $mdu);

  .info-section__questions {
    margin: 0 auto;
    padding: ($mdu * 5) 0 ($mdu * 8) 0;
  }

  .info-section__question {
    display: inline-block;
    margin-bottom: ($mdu * 4);
    width: 100%;

    h3 {
      font-size: $font-size-18;
      font-weight: $font-weight-semibold;
      line-height: ($mdu * 2.75);
    }

    div {
      line-height: ($mdu * 2.5);
      margin-top: ($mdu * 2);

      li {
        list-style: disc;
        list-style-position: inside;
      }
    }
  }
}
