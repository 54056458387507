@mixin box-hover-shadow() {
  transition: box-shadow $transition-duration $transition-timing-function;
  box-shadow: 0 7px 16px 0 rgb(0 0 0 / 20%), 0 1px 3px 0 rgb(0 0 0 / 10%);
}

@mixin prev-button-resize() {
  @media screen and (width <= 1180px) {
    .prev-button {
      left: 1px;
    }
  }
}

// See spacing in _utilities.scss
@mixin spacing($type, $direction, $size, $important: '') {
  @if $direction == 'x' {
    #{$type}-left: $size #{$important};
    #{$type}-right: $size #{$important};
  } @else if $direction == 'y' {
    #{$type}-top: $size #{$important};
    #{$type}-bottom: $size #{$important};
  } @else if $direction == 'a' {
    #{$type}: $size #{$important};
  } @else {
    #{$type}-#{$direction}: $size #{$important};
  }
}

@mixin spacing-padding($direction, $size, $important: '') {
  @include spacing('padding', $direction, $size, $important);
}

@mixin spacing-margin($direction, $size, $important: '') {
  @include spacing('margin', $direction, $size, $important);
}

@mixin reset-margin-padding() {
  margin: 0;
  padding: 0;
}
