$gray-800: #eee;
$gray-825: #e6e6e6;
$blue-100: #3483fa;
$font-size-base: 16px;
$font-weight-base: 300;
$font-family-primary: 'Proxima Nova', -apple-system, 'Helvetica Neue', helvetica,
  'Roboto', arial, sans-serif;
$line-height-base: 1.35;
$body-bg-color: $gray-800;
$tap-highlight-color: rgb(0 0 0 / 10%);
$text-selection-color: $gray-825;
$line-height-s: 1.25;
$link-color: $blue-100;

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background: $body-bg-color;
}

main[role='main'] {
  font-family: $font-family-primary;
  font-size: $font-size-base;
  font-weight: 300;
  line-height: $line-height-base;

  // Propietary webkit rule: avoid font resizing on device rotation
  text-size-adjust: 100%;

  // Propietary webkit rule: standarize cross device tap highlight color
  -webkit-tap-highlight-color: $tap-highlight-color;
}

::selection {
  background: $text-selection-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 400;
  line-height: $line-height-s;
}

p {
  margin: 0;
}

a,
a:link,
a:hover,
a:visited {
  text-decoration: none;
  color: $link-color;
}

ol,
ul {
  @include reset-margin-padding;
}

li {
  list-style: none;
}

button,
textarea {
  font-family: inherit;
}
