@import './core';

.lgpd-landing__title-section {
  margin-top: ($mdu * 8);
  max-width: $max-width;

  .title-section__icon {
    padding-bottom: ($mdu * 7);
  }

  .title-section__text {
    max-width: ($mdu * 64);
    width: 100%;
    margin: 0 auto;
    padding: ($mdu * 4) 0 ($mdu * 6) 0;
  }
}
