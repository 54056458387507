.how-buy-landing__faqs {
  background: $white;
  padding: ($mdu * 6) 0 ($mdu * 7) 0;

  .faqs__title {
    font-size: $font-size-20;
    font-weight: $font-weight-semibold;
    text-align: center;
  }

  .faqs__list {
    margin: 0 auto;
    max-width: ($mdu * 115);
    padding-top: ($mdu * 6.5);
  }

  .faqs__item {
    border-bottom: 1px solid $andes-gray-100;

    .faqs__item-content:focus {
      outline: 0;
    }

    .faqs__item-title-button {
      box-sizing: border-box;
      color: $andes-gray-900-solid;
      display: flex;
      justify-content: space-between;
      padding: ($mdu * 3);

      .faqs__item-title {
        font-size: $font-size-18;
      }

      .faqs__item-icon {
        align-items: center;
        display: flex;
        position: relative;
        top: 0;
        transform: rotate(360deg);
        transition: transform 0.1s ease-in-out;
      }

      .faqs__item-icon--disabled {
        align-items: center;
        display: flex;
        position: relative;
        top: 0;
        transform: rotate(180deg);
      }
    }

    .faqs__item-text {
      color: $andes-gray-550-solid;
      padding: 0 ($mdu * 3) ($mdu * 3) ($mdu * 3);
      pointer-events: none;
      transition: 0.3s, opacity 0.1s;
    }

    .faqs__item-text--disabled {
      height: 0;
      opacity: 0;
      padding: 0 ($mdu * 3) 0 ($mdu * 3);
      position: relative;
      transition: 0.3s, opacity 0.1s;
    }
  }
}
