.buyer-info {
  background-color: $andes-white;
  overflow: hidden;

  .container {
    display: flex;
    justify-content: center;
    margin-bottom: rem($andes-spacing-24);
  }

  .buyer-info__card {
    align-items: center;
    border-radius: rem($border-radius-6);
    border: solid 1px $andes-gray-100;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 rem($andes-spacing-20);
    padding: rem($andes-spacing-16);
  }

  p {
    color: $andes-gray-550-solid;
    font-size: $font-size-14;
    line-height: 1.2;
    margin: rem($andes-spacing-4) 0 rem($andes-spacing-32) 0;
  }

  a {
    cursor: pointer;
    font-size: $font-size-14;
  }
}
