.grecaptcha-badge {
  visibility: hidden;
}

.newsletter-modal__content-close {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: 0;
  top: rem(-36px);

  path {
    fill: $andes-white;
  }
}

.newsletter-modal .andes-modal__close-button {
  display: none;
}

.newsletter-form__disclaimer {
  color: $andes-gray-550;
}

.newsletter-modal--image-shadow {
  display: none;
}
